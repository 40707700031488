import { BrowserRouter, Route, Routes } from 'react-router-dom'
import { Login } from '../app/auth/component/Login'
import { ResetPassword } from '../app/auth/component/ResetPassword'
import { AuthLayout } from '../app/shared/component/layout/AuthLayout'
import { ProtectedLayout } from '../app/shared/component/layout/ProtectedLayout'
import { Dashboard } from '../app/shared/component/dashboard/Dashboard'
import { Signup } from '../app/auth/component/Signup'
import { AuthCallback } from '../app/auth/component/AuthCallback'
import { Profile } from '../app/user/component/Profile'
import { Settings } from '../app/shared/component/Settings'
import { ContactPage } from '../app/contact/component/ContactPage'
import { ItemPage } from '../app/inventory/component/ItemPage'
import { OrderPage } from '../app/order/component/OrderPage'
import { AppraisalPage } from '../app/appraisal/component/AppraisalPage'
import { WeddingRegistryPage } from '../app/wedding-registry/component/WeddingRegistryPage'
import { ItemFormUpdateImages } from '../app/inventory/component/ItemFormUpdateImages'
import { SalesByVendorPage } from '../app/reports/sales-by-vendor/component/SalesByVendorPage';
import { StoneInventoryManagement } from '../app/reports/sales-by-vendor/component/StoneInventoryManagement'
import { AppointmentList } from '../app/appointment/component/AppointmentList'
import { OnlineOrderList } from '../app/order/component/OnlineOrderList'
import {
  SalesByCustomerServerRenderingPage
} from '../app/reports/sales-by-customer/component/SalesByCustomerServerRenderingPage'
import {
  OrderPaymentsPageServerRendering
} from '../app/reports/order-payments/component/OrderPaymentsPageServerRendering'

const routes = {
  authRoutes: [
    { path: '/login', element: <Login /> },
    { path: '/reset-password', element: <ResetPassword /> },
    { path: '/signup', element: <Signup /> },
    { path: '/auth', element: <AuthCallback /> },
  ],

  privateRoutes: [
    { path: '/', element: <Dashboard /> },
    { path: '/appraisal', element: <AppraisalPage /> },
    { path: '/inventory', element: <ItemPage /> },
    { path: '/vendor', element: <h1>Vendor</h1> },
    { path: '/order/:number?', element: <OrderPage /> },
    { path: '/online-orders', element: <OnlineOrderList/>},
    { path: '/wedding-registery', element: <WeddingRegistryPage /> },
    { path: '/upload-images', element: <ItemFormUpdateImages/>},
    { path: '/reports/sales-by-customer', element: <SalesByCustomerServerRenderingPage/>},
    { path: '/reports/sales-by-vendor', element: <SalesByVendorPage/>},
    { path: '/reports/order-payments', element: <OrderPaymentsPageServerRendering/>},
    { path: '/reports/stone-inventory-management', element: <StoneInventoryManagement/>},
    { path: '/appointments', element: <AppointmentList/>},

    { path: '/profile', element: <Profile /> },
    { path: '/contact/:contactId?', element: <ContactPage /> },
    { path: '/purchase-order', element: <h1>Purchase Order</h1> },
    { path: '/receivables', element: <h1>Receivables</h1> },
    { path: '/settings', element: <Settings /> },
  ],
}

export function AppRoutes() {
  return (
    <>
      <BrowserRouter>
        <div className='App'>
          <Routes>
            <Route element={<AuthLayout />}>
              {routes.authRoutes.map((route, index) => (
                <Route
                  key={`auth-${index}`}
                  path={route.path}
                  element={route.element}
                />
              ))}
            </Route>

            <Route element={<ProtectedLayout />}>
              {routes.privateRoutes.map((route, index) => (
                <Route
                  key={`private-${index}`}
                  path={route.path}
                  element={route.element}
                />
              ))}
            </Route>
          </Routes>
        </div>
      </BrowserRouter>
    </>
  )
}
