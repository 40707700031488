import React, { useState } from 'react'
import { Combo, Container } from '../../../../core/component'
import { Button, Checkbox, DatePicker, Form, Input, Space } from "antd"
import { translate } from "../../../../core"
import { dateDisplay, dateFormat, dateFormatSlashed } from "../../../../core/until"
import { useLazyQuery } from "@apollo/client"
import { InputCard } from "../../../inventory/component/InputCard"
import { SalesByVendorRequest } from "../type/vendor-report"
import SalesByVendorReport from "./SalesByVendorReport"
import { GetSalesByVendorDocument } from "../../../order/graphql/order-report-graphql"
import { ItemType } from "../../../inventory/type"
import { getItemTypeComboOptions } from "../../../inventory/func/type-options"

export const SalesByVendorPage = () => {
  const [getPrint, {loading}] = useLazyQuery<{ salesByVendor: any }>(GetSalesByVendorDocument)
  const [data, setData] = useState<any>()
  const [form] = Form.useForm<SalesByVendorRequest>()
  const [isChina, setIsChina] = useState(false)
  const [request, setRequest] = useState<any>()

  const handleOK = async (values: SalesByVendorRequest) => {
    const response = await getPrint({
      variables: {
        request: {
          minDate: values.minDate ? dateDisplay(values.minDate.toDate(), dateFormatSlashed) : undefined,
          maxDate: values.maxDate ? dateDisplay(values.maxDate.toDate(), dateFormatSlashed) : undefined,
          vendorIdFrom: values.vendorIdFrom,
          vendorIdTo: values.vendorIdTo,
          type: values.type
        }
      }
    })
    if (response.data) {
      setData(response?.data)
    } else {
      setData(undefined)
    }
    setRequest({
      minDate: values.minDate ? dateDisplay(values.minDate.toDate(), dateFormatSlashed) : undefined,
      maxDate: values.maxDate ? dateDisplay(values.maxDate.toDate(), dateFormatSlashed) : undefined,
      vendorIdFrom: values.vendorIdFrom,
      vendorIdTo: values.vendorIdTo,
      type: values.type
    })
  }

  return (
    <>
      <div className='print-hidden'>
        <Container
          leftClassName="action-container"
          paddingClass="0"
          loading={loading}
        >
          <InputCard className="content-size">
            <Form
              layout='vertical'
              form={form}
              onFinish={handleOK}
              className='d-flex flex-column gap-2'
            >
              <Space className='w-100 ' size='middle'>
                <label style={{width: 100}}>{translate('type')}</label>
                <Form.Item
                  className='mb-1'
                  name='type'
                >
                  <Combo<any, ItemType>
                    allowMultiple
                    size='middle'

                    options={getItemTypeComboOptions()}
                    style={{width: 400}}

                  />
                </Form.Item>
              </Space>
              <Space className='w-100' size='middle'>
                <label style={{width: 100}}></label>
                <label style={{width: 192}}>{translate('from')}</label>
                <label style={{width: 180}}>{translate('to')}</label>
              </Space>
              <Space className='w-100 ' size='middle'>
                <label style={{width: 100}}>{translate('vendor')}</label>
                <Form.Item
                  className='mb-1'
                  name='vendorIdFrom'
                >
                  <Input style={{width: 192}}/>
                </Form.Item>
                <Form.Item
                  className='mb-1'
                  name='vendorIdTo'
                >
                  <Input style={{width: 192}}/>
                </Form.Item>
              </Space>
              <Space className='w-100 ' size='middle'>
                <label style={{width: 100}}>{translate('date')}</label>

                <Form.Item
                  name='minDate'
                  className='mb-1'
                  rules={[
                    {required: true, message: translate('min', 'date', 'is_required')}
                  ]}
                >
                  <DatePicker format={dateFormat} style={{width: 192}}/>
                </Form.Item>

                <Form.Item
                  name='maxDate'
                  className='mb-1'
                  rules={[
                    {required: true, message: translate('max', 'date', 'is_required')}
                  ]}
                >
                  <DatePicker format={dateFormat} style={{width: 192}}/>
                </Form.Item>
              </Space>
              <Space className='w-100 ' size='middle'>
                <label style={{width: 100}}>{translate('China version')}</label>
                <Checkbox onChange={() => setIsChina(!isChina)}/>
              </Space>
              <Space className='gap-1'>
                <Button
                  onClick={() => form.resetFields()}
                >
                  {translate('clear')}
                </Button>
                <Button
                  type='primary'
                  onClick={form.submit}
                >
                  {translate('print')}
                </Button>
              </Space>
            </Form>
          </InputCard>
        </Container>
      </div>
      <SalesByVendorReport data={data} isChina={isChina}/>
    </>
  )
}
