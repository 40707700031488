import React, { useState } from 'react'
import { Container, StimulsoftViewer } from '../../../../core/component'
import { Button, DatePicker, Form, Input, Space } from 'antd'
import { translate } from '../../../../core'
import { dateFormat } from '../../../../core/until'
import { InputCard } from '../../../inventory/component/InputCard'
import { SalesByCustomerRequest } from '../type/customer-report'

export const SalesByCustomerServerRenderingPage = () => {
  const [form] = Form.useForm<SalesByCustomerRequest>()
  const [request, setRequest] = useState<Record<string, any>>()

  return (
    <>
      <div>
        <Container
          leftClassName='action-container'
          paddingClass='0'
        >
          <InputCard className='content-size'>
            <Form
              layout='vertical'
              form={form}
              onFinish={data => {
                setRequest({
                  ...data,
                  minDate: data.minDate ? data.minDate.toISOString() : undefined,
                  maxDate: data.maxDate ? data.maxDate.toISOString() : undefined,
                })
              }}
              className='d-flex flex-column gap-2'
            >
              <Space className='w-100' size='middle'>
                <label style={{width: 100}}></label>
                <label style={{width: 180}}>{translate('from')}</label>
                <label style={{width: 180}}>{translate('to')}</label>
              </Space>
              <Space className='w-100 ' size='middle'>
                <label style={{width: 100}}>{translate('customer', 'No')}</label>
                <Form.Item
                  className='mb-1'
                  name='customerNumberForm'
                >
                  <Input/>
                </Form.Item>
                <Form.Item
                  className='mb-1'
                  name='customerNumberTo'
                >
                  <Input/>
                </Form.Item>
              </Space>
              <Space className='w-100 ' size='middle'>
                <label style={{width: 100}}>{translate('date')}</label>

                <Form.Item
                  name='minDate'
                  className='mb-1'

                >
                  <DatePicker format={dateFormat} style={{width: 192}}/>
                </Form.Item>

                <Form.Item
                  name='maxDate'
                  className='mb-1'

                >
                  <DatePicker format={dateFormat} style={{width: 192}}/>
                </Form.Item>
              </Space>
              <Space className='gap-1'>
                <Button
                  onClick={() => form.resetFields()}
                >
                  {translate('clear')}
                </Button>
                <Button
                  type='primary'
                  onClick={form.submit}
                >
                  {translate('print')}
                </Button>
              </Space>
            </Form>
          </InputCard>
        </Container>

        <StimulsoftViewer
          name='sales-by-customer'
          request={request}
          skip={!request}
        />
      </div>
    </>
  )
}
