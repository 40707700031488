import { useEffect, useState } from "react"
import { Button, DatePicker, Form, Input, Select, Tooltip } from "antd"
import { CloseOutlined, PlusOutlined } from "@ant-design/icons"
import { translate } from "../../../core"
import { dateFormat } from "../../../core/until"
import styles from "./Order.module.scss"
import dayjs from "dayjs"
import { AutoComplete, NumericInput } from "../../../core/component"
import { useLazyQuery } from '@apollo/client'
import { GetPaymentMethods } from '../../shared/graphql/payment-method.queries.graphql'
import { PaymentMethod } from '../../shared/type'

interface OrderEntryLinesProps {
  onAmountsChanged: () => void
}

export const OrderEntryPayments = ({onAmountsChanged}: OrderEntryLinesProps) => {
  const [forceUpdate, setForceUpdate] = useState<boolean>(false)
  const [methods, setMethods] = useState<string[]>([])
  const [getPaymentMethods] = useLazyQuery<{ paymentMethodsFind: PaymentMethod[] }>(GetPaymentMethods)

  const onPaymentChanged = () => {
    onAmountsChanged()
  }

  const fetchMethods = async () => {
    const {data} = await getPaymentMethods()
    const list = data?.paymentMethodsFind || []
    setMethods(
      list.map(pm => pm.name),
    )
  }

  useEffect(() => {
    setForceUpdate(false)
  }, [forceUpdate])

  useEffect(() => {
    fetchMethods()
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  return (
    <Form.List name="payments">
      {(fields, {add, remove}) => (
        <>
          <Form.Item style={{marginBottom: "0"}}>
            <Button
              onClick={() => add({date: dayjs()})}
              icon={<PlusOutlined/>}
            >
              {translate("add", "payments")}
            </Button>
          </Form.Item>

          <table className="table">
            <thead>
            <tr>
              <th className="text-center">{translate("date")}</th>
              <th className="text-center">{translate("amount")}</th>
              <th className="text-center" style={{width: "8%"}}>
                {translate("payment_method")}
              </th>
              <th className="text-center" style={{width: "30%"}}>
                {translate("card_number")}
              </th>
              <th className="text-center">
                {translate("card_expire_month")}
              </th>
              <th className="text-center">{translate("card_expire_year")}</th>
              <th className="text-center">
                {translate("authorization", "code")}
              </th>
              <th className="text-center" style={{width: "3%"}}></th>
            </tr>
            </thead>

            <tbody className={styles["orderLines"]}>
            {fields.map((field, index) => (
              <tr key={`${field.name}-${field.key}`}>
                <td>
                  <Form.Item
                    name={[field.name, "date"]}
                    rules={[
                      {
                        required: true,
                        message: translate("missing_field_message", "date"),
                      },
                    ]}
                    className="date"
                  >
                    <DatePicker
                      format={dateFormat}
                      style={{width: "100%"}}
                    />
                  </Form.Item>
                </td>

                <td>
                  <Form.Item
                    rules={[
                      {
                        required: true,
                        message: translate("missing_field_message", "amount"),
                      },
                    ]}
                    name={[field.name, "amount"]}
                  >
                    <NumericInput
                      className={`text-center`}
                      onChange={() => onPaymentChanged()}
                    />
                  </Form.Item>
                </td>

                <td>
                  <Form.Item
                    name={[field.name, "method"]}
                    rules={[
                      {
                        required: true,
                        message: translate("missing_field_message", "method"),
                      },
                    ]}
                    className={styles["orderLineItemNumber"]}
                  >
                    <AutoComplete style={{width: "100%"}} items={methods}/>
                  </Form.Item>
                </td>

                <td>
                  <Form.Item name={[field.name, "cardNumber"]}>
                    <Input
                      className={`${styles.readOnlyInput} text-center`}
                    />
                  </Form.Item>
                </td>

                <td>
                  <Form.Item name={[field.name, "expireMonth"]}>
                  <Select
                    onChange={() => onPaymentChanged()}
                    options={Array.from({ length: 12 }, (_, i) => i + 1).map((num) => (
                      { value: num, label: num }
                    ))}
                  />
                  </Form.Item>
                </td>

                <td>
                  <Form.Item name={[field.name, "expireYear"]}>
                  <Select
                    style={{width: '6rem'}}
                    onChange={() => onPaymentChanged()}
                    options={Array.from({ length: 11 }, (_, i) => {
                      const year = new Date().getFullYear() - 5 + i; 
                      return (
                        { value: year, label: year }
                      );
                    })}
                  />
                  </Form.Item>
                </td>

                <td>
                  <Form.Item name={[field.name, "authorizationCode"]}>
                    <Input
                      className={`${styles.readOnlyInput} ${styles.amountInput}`}
                    />
                  </Form.Item>
                </td>

                <td>
                  <Tooltip title={translate("remove")}>
                    <Button
                      onClick={() => {
                        remove(index)
                        onPaymentChanged()
                      }}
                      type="text"
                      shape="circle"
                      icon={<CloseOutlined/>}
                    />
                  </Tooltip>
                </td>
              </tr>
            ))}
            </tbody>
          </table>
        </>
      )}
    </Form.List>
  )
}
