import * as React from 'react'
import { FC } from 'react'
import { memory } from '../index'
import { TokenResponse } from '../../app/auth/type'
import { AUTHENTICATION_TOKEN } from '../../App.constants'
import qs from 'qs'
import { Nullable } from '../type'
import { Container } from './Container'
import { InputCard } from '../../app/inventory/component/InputCard'

export type StimulsoftViewerProps = {
  name: string
  request: Nullable<Record<string, any>>
  skip?: boolean
}
export const StimulsoftViewer: FC<StimulsoftViewerProps> = ({name, request, skip}) => {
  const token = memory.get<TokenResponse>(AUTHENTICATION_TOKEN)

  const resolveURL = () => {
    console.log(request)
    const stringify = qs.stringify({
      authorization: `${token?.token_type} ${token?.access_token}`,
      ...request ?? {},
    })

    console.log(stringify)
    const queryString = stringify ? `?${stringify}` : ''
    return `${process.env.REACT_APP_API_BASE_URL}/v1/report/view/${name}${queryString}`
  }

  return (
    <>
      {
        !skip &&
        <Container
          leftClassName='action-container'
          paddingClass='0'
        >
          <InputCard className='content-size'>
            <iframe
              src={resolveURL()}
              className='w-100'
              style={{height: 'calc(100vh - 21em)'}}
            />
          </InputCard>
        </Container>
      }
    </>
  )
}