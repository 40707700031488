import React, { useState } from 'react'
import { Button, DatePicker, Form, Space } from "antd"
import { translate } from "../../../../core"
import { dateFormat } from "../../../../core/until"
import { InputCard } from "../../../inventory/component/InputCard"
import { OrderPaymentRequest, OrderPaymentType } from "../../../order/type"
import { Combo, Container, enumToComboOptionTransformer, StimulsoftViewer } from '../../../../core/component'

export const OrderPaymentsPageServerRendering = () => {
  const [request, setRequest] = useState<Record<string, any>>()
  const [form] = Form.useForm<OrderPaymentRequest>()

  return (
    <>
      <div className='print-hidden'>
        <Container
          leftClassName="action-container"
          paddingClass="0"
        >
          <InputCard className="content-size">
            <Form
              layout='vertical'
              form={form}
              onFinish={data => {
                setRequest({
                  type: data.type,
                  minDate: data.minDate ? data.minDate.toISOString() : undefined,
                  maxDate: data.maxDate ? data.maxDate.toISOString() : undefined,
                })
              }}
            >
              <Form.Item
                name='type'
                label={translate('type')}
                rules={[
                  {required: true, message: translate('type', 'is_required')}
                ]}
                style={{maxWidth: 300}}
              >
                <Combo<any, OrderPaymentType>
                  size="middle"
                  options={enumToComboOptionTransformer(OrderPaymentType)}

                />
              </Form.Item>

              <Space className='w-100'>
                <Form.Item
                  name='minDate'
                  label={translate('min', 'date')}
                  rules={[
                    {required: true, message: translate('min', 'date', 'is_required')}
                  ]}
                >
                  <DatePicker format={dateFormat} style={{width: "100%"}}/>
                </Form.Item>

                <Form.Item
                  name='maxDate'
                  label={translate('max', 'date')}
                  rules={[
                    {required: true, message: translate('max', 'date', 'is_required')}
                  ]}
                >
                  <DatePicker format={dateFormat} style={{width: "100%"}}/>
                </Form.Item>
              </Space>
              <Space className='gap-1'>
                <Button
                  onClick={() => form.resetFields()}
                >
                  {translate('clear')}
                </Button>
                <Button
                  type='primary'
                  onClick={form.submit}
                >
                  {translate('print')}
                </Button>
              </Space>
            </Form>
          </InputCard>
        </Container>

        <StimulsoftViewer
          name='order-payments'
          request={request}
          skip={!request}
        />
      </div>

    </>
  )
}
