function parse<TParameters>(url: string): { url: string, parameters: TParameters } {
  const instance = new URL(url);

  const originURL = instance.origin;

  let params: any = parseQuery(instance.search);

  return {
    url: originURL,
    parameters: params
  };
}

function parseQuery(queryString: any) {
  let query: any = {};
  let pairs = (queryString[0] === '?' ? queryString.substr(1) : queryString).split('&');
  for (let i = 0; i < pairs.length; i++) {
    const pair = pairs[i].split('=');
    query[decodeURIComponent(pair[0])] = decodeURIComponent(pair[1] || '');
  }
  return query;
}

const stringifyQuery = (args: Record<string, any>) => {
  const params = new URLSearchParams()
  Object.entries(args).forEach(([key, value]) => params.append(key, value))
  return params.toString()
}

export const queryString = {
  parse,
  stringifyQuery,
}
