import { useContext, useState } from 'react'
import { DatePicker, Form, Input, Row, Space } from 'antd'
import { translate } from '../../../core'
import { Contact, WebInfoType } from '../../contact/type/contact'
import { OrderEntryPhones } from './OrderEntryPhones'
import { OrderFormLines } from './OrderFormLines'
import { OrderEntryFooter } from './OrderEntryFooter'
import { dateFormat } from '../../../core/until'
import { Order, OrderEntry } from '../type'
import { PageContextValue } from '../../../core/type'
import { InputCard } from '../../inventory/component/InputCard'
import styles from './Order.module.scss'
import { OrderEntryPayments } from './OrderEntryPayments'
import { mapEntry } from '../model-mapper/order-map-entity-to-entry'
import { OrderFormAddresses } from './OrderFormAddresses'
import { createAddressByCustomer } from '../model-mapper/create-address'
import { OrderFormRepairsDisplay } from './OrderFormRepairsDisplay'
import { OrderFormWeddingRegistrySelect } from './OrderFormWeddingRegistrySelect'
import { WeddingRegistry } from '../../wedding-registry/type'
import { ContactSelect } from '../../contact/component/ContactSelect'
import { OrderPageContext } from './OrderPageContext'
import { OrderFormRepair } from './OrderFormRepair'
import { CopyToClipboard, ShowIf } from '../../../core/component'
import { OrderFormRelationDisplay } from './OrderFormRelationDisplay'
import { CheckSquareOutlined } from '@ant-design/icons'

export const OrderForm = () => {
  const [showRepair, setShowRepair] = useState<boolean>(false)
  const [selectedRepairVersion, setSelectedRepairVersion] = useState<string>()
  const [weddingRegistry, setWeddingRegistry] = useState<WeddingRegistry>()
  const {form, entity, onFormChange, save} = useContext<PageContextValue<Order, OrderEntry>>(OrderPageContext)
  const [customerId, setCustomerId] = useState<string>('')
  const [customer, setCustomer] = useState<Contact>()

  const onAmountsChanged = () => {
    const entry = form?.getFieldsValue() as OrderEntry
    mapEntry(entry, entity?.taxRate as number)
    form?.setFieldsValue(entry as any)
  }

  const onRepairSelect = (version: string) => {
    setSelectedRepairVersion(version)
    setShowRepair(true)
  }

  const handleCustomerChange = (customer?: Contact) => {
    const entry = form?.getFieldsValue() as OrderEntry

    if (customer)
      entry.customerEmail = customer.webInfos.find(e => e.type === WebInfoType.EmailAddress)?.value ?? ''

    entry.customerWork = customer?.phones?.length
      ? customer.phones[0]?.number ?? ''
      : ''

    createAddressByCustomer(entry, customer)
    form?.setFieldsValue(entry as any)
  }

  const handleSameAddressChange = () => {
    const entry = form?.getFieldsValue() as OrderEntry
    createAddressByCustomer(entry, customer)
    form?.setFieldsValue(entry as any)
  }

  return (
    <Form
      onFieldsChange={onFormChange}
      layout='vertical'
      name='contentEntry'
      form={form}
      onFinish={save}
    >
      <OrderFormRepair
        open={showRepair}
        onClose={() => setShowRepair(false)}
        version={selectedRepairVersion}
        onChangeVersion={setSelectedRepairVersion}
        onAmountChange={onAmountsChanged}
      />

      <ShowIf condition={!!entity?.online} replacement={<></>}>
        <Space style={{color: '#0564ee'}}>
          <CheckSquareOutlined style={{fontSize: 20}}/>
          <label>{translate('online')}</label>
        </Space>
      </ShowIf>
      <br />
      <InputCard className={styles['customerInfoCardWrapper']}>
        <Row align='bottom' className={styles['customerInfoCard']}>
          <Form.Item label={translate('order_number_abbr')} name='number' className='order-no'>
            <Input disabled={true} style={{color: 'unset'}}/>
          </Form.Item>
          <Form.Item
            label={<Space>
              {translate('contact_id')}
              <CopyToClipboard
                text={customerId}
                tooltip={translate('copy', 'contact_id')}
              />
            </Space>}
            name={['customer', 'id']}
            className='customer-no'
          >
            <ContactSelect
              width={250}
              onCustomerChangedOrBound={((customer, isBound) => {
                setCustomerId(customer?.contactId)
                setCustomer(customer)

                if (isBound)
                  return

                handleCustomerChange(customer)
              })}
            />
          </Form.Item>
          <Form.Item
            label={translate('date')}
            name='date'
            rules={[
              {
                required: true,
                message: translate('missing_field_message', 'date'),
              },
            ]}
            className='date'
          >
            <DatePicker format={dateFormat} style={{width: '100%'}}/>
          </Form.Item>
          <OrderEntryPhones/>

          <Form.Item label={translate('email')} name='customerEmail' className='email'>
            <Input/>
          </Form.Item>

          <Form.Item
            label={translate('clerk')}
            name='clerk'
            className='clerk'
          >
            <Input/>
          </Form.Item>
          <Form.Item
            label={translate('registry_number')}
            name={['weddingRegistry', 'id']}
            className='registry-no'
          >
            <OrderFormWeddingRegistrySelect
              onSelect={setWeddingRegistry}
            />
          </Form.Item>
        </Row>
      </InputCard>
      <br />
      <OrderFormAddresses onSameAddressChange={handleSameAddressChange}/>
      <br />
      <OrderEntryFooter onChanged={onAmountsChanged}/>
      <br />
      <OrderFormLines
        onSelectRepair={onRepairSelect}
        onAmountsChanged={onAmountsChanged}
        weddingRegistry={weddingRegistry}
      />
      <br />
      <OrderEntryPayments onAmountsChanged={onAmountsChanged} />
      <br />
      <OrderFormRepairsDisplay onSelectRepair={onRepairSelect} />
      <br />
      <OrderFormRelationDisplay customerId={customerId} />
    </Form>
  )
}
