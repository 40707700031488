import { Flex, Form, Space } from "antd";
import { translate } from "../../../core";
import { NumericInput } from "../../../core/component";

export const ItemFormQuantity = () => {
  return (
    <Space>
      <Flex vertical>
        <label>{translate("on_hand")}</label>
        <Form.Item noStyle name={["quantity", "onHand"]}>
          <NumericInput decimalPlaces={0} />
        </Form.Item>
      </Flex>
      <Flex vertical>
        <label>{translate("display")}</label>
        <Form.Item noStyle name={["quantity", "display"]}>
          <NumericInput decimalPlaces={0} />
        </Form.Item>
      </Flex>
      <Flex vertical>
        <label>{translate("on_order")}</label>
        <Form.Item noStyle name={["quantity", "onOrder"]}>
          <NumericInput decimalPlaces={0} />
        </Form.Item>
      </Flex>
      <Flex vertical>
        <label>{translate("back_order")}</label>
        <Form.Item noStyle name={["quantity", "backOrder"]}>
          <NumericInput decimalPlaces={0} />
        </Form.Item>
      </Flex>
      <Flex vertical>
        <label>{translate("in_set")}</label>
        <Form.Item noStyle name={["quantity", "inSet"]}>
          <NumericInput decimalPlaces={0} />
        </Form.Item>
      </Flex>
      <Flex vertical>
        <label>{translate("committed")}</label>
        <Form.Item noStyle name={["quantity", "committed"]}>
          <NumericInput decimalPlaces={0} />
        </Form.Item>
      </Flex>
      <Flex vertical>
        <label>{translate("min")}</label>
        <Form.Item noStyle name={["quantity", "min"]}>
          <NumericInput decimalPlaces={0} />
        </Form.Item>
      </Flex>
      <Flex vertical>
        <label>{translate("max")}</label>
        <Form.Item noStyle name={["quantity", "max"]}>
          <NumericInput decimalPlaces={0} />
        </Form.Item>
      </Flex>
    </Space>
  );
};
